.App {
  text-align: center;
  background-color:#323238;
}

html{
  background-color: #9CBD5E;
  height: 100%;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

body{
  overflow: auto;
  height:100%;
  width:100%;
  background-color: #323238;
  font-family: "Roboto Slab";
  height: 99%;
    width: 100%;
    background-color: #323238;
    font-family: "Roboto Slab";
}

.topLeftName{
  position: absolute;
  left: 0.6em;
  top: 3vh;
  color: #EBCD8F;
  font-size: 3em;
}

.code{
  text-decoration: underline;
  text-decoration-color: #9CBD5E;
  text-underline-offset: .25em;
  color:#DEC6B2;
}

.alertsContainer{
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width:100%;
  flex-direction: column;
}

.alert{
  display: flex;
  margin:1em;
  background-color: #DEC6B2;
  border-radius: 1em;
  font-size: 1.2em;
  width: 80%;
  padding-top: 2em;
  padding-bottom: 2em;
  max-width: 1000px;
}

.alertLargeText{
  margin-bottom: 0;
  color: #323238;
  font-size: 3em;
  padding: 0.25em;
}

.alertLeft{
  width:20%;
  display:flex;
  flex-direction:column;
  align-items: center;
}

.alertTime{
  margin-top:.4em;
}

.alertRight{
  width:80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contentContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30vh;
}
 
.contentContainer .contentLines{
  color:#F7DDC6;
  font-size:4em;
  margin-bottom:1.8em;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
